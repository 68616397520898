import { defineStore } from "pinia";

const useDocumentStore = defineStore("document", {
  state: () => ({
    items: [
      {
        id: "1",
        title: "章节 1",
        details: [
          {
            id: "1-1",
            content: "关于章节 1 的细节 1...",
            loading: true,
            posFlag: "start",
          },
          {
            id: "1-2",
            content: "关于章节 1 的细节 2...",
            loading: true,
          },
        ],
      },
      {
        id: "2",
        title: "章节 2",
        details: [
          {
            id: "2-1",
            content: "关于章节 2 的细节 1...",
            loading: true,
            posFlag: "end",
          },
        ],
      },
      // 更多章节...
    ],
    chineseItems: {
      委托人: {
        姓名: "曹一操",
        身份证号: "420212345601234569",
        住所: "13213123",
      },
      受委托人1: {
        姓名: "21312312",
        职务: "321312",
        电话: "312312312",
        工作单位: "北京金台（武汉）律师事务所",
      },
      受委托人2: {
        姓名: "321312321",
        职务: "321312",
        电话: "32131231",
        工作单位: "北京金台（武汉）律师事务所",
      },
      案件: {
        类型: "借款合同纠纷",
        对方: "刘二备",
      },
      代理权限: "特别授权代理",
      委托日期: {
        年: "12313",
        月: "321312",
        日: "321312",
      },
    },
    currentSectionTitle: "", // 用户输入的关键词
    historyDocument: "", // 用户历史文书的内容
    taskId: "",
    taskPoint: "",
    tableData: [] as Array<{ key: string; name: string; type: string }>,
    deleteFileId: "",
    selectedCloudLink: [] as Array<{
      fileId: string;
      title: string;
      link: string;
      snippet: string;
    }>,
    templateFileList: [],
    type: 0,
    formattedContent: "",
  }),
  actions: {
    // 设置整个章节内容
    setItems(chapter: any) {
      this.items = chapter;
    },
    setChineseItems(chapter: any) {
      this.chineseItems = chapter;
    },
    // 更新当前章节标题
    setCurrentSectionTitle(title: string) {
      this.currentSectionTitle = title;
    },
    // 更新历史文书内容
    setHistoryDocument(document: string) {
      this.historyDocument = document;
    },
    // 更新任务ID
    setTaskId(taskId: string) {
      this.taskId = taskId;
    },
    setTaskPoint(taskPoint: string) {
      this.taskPoint = taskPoint;
    },
    addTableData(data: any) {
      this.tableData.push(data);
    },
    addTableDataList(data: any) {
      const list = this.tableData;
      const newList = [...list, ...data];
      this.tableData = newList;
    },
    setSelectedCloudLink(data: any) {
      this.selectedCloudLink = data;
    },
    setDeleteFileId(fileId: string) {
      this.deleteFileId = fileId;
    },
    setType(type: number) {
      this.type = type;
    },
    setTemplateFileList(data: any) {
      this.templateFileList = data;
    },
    setChineseContent(data: any) {
      this.formattedContent = data;
    },
    resetDocument() {
      this.$reset();
    },
  },
});

export default useDocumentStore;
