<!-- PaymentConfirmModal.vue -->
<template>
  <n-modal
    :show="show"
    class="custom-card"
    preset="card"
    size="huge"
    :bordered="false"
    header-style="padding:15px"
    :closable="false"
    :style="{ width: '700px' }"
  >
    <template v-if="!paymentResult">
      <div
        style="
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <p>支付是否已完成？</p>
        <div class="customBtn">
          <n-button type="info" @click="handlePaymentCompleted"
            >已完成</n-button
          >
          <n-button type="default" @click="closeModal">未完成</n-button>
        </div>
      </div>
    </template>

    <template v-else-if="paymentResult === 'success'">
      <div style="text-align: center">
        <n-icon size="60" color="#0e7a0d">
          <CheckmarkCircle />
        </n-icon>
        <h3 style="margin-bottom: 50px">下单成功</h3>
        <div style="text-align: left; padding-left: 80px">
          <p>购买商品: {{ orderInfo!.rightsName }}</p>
          <p>持续时间: {{ orderInfo!.validDate }}天</p>
          <p>支付方式: {{ orderInfo!.paymentMethod }}</p>
          <p>实付金额: {{ orderInfo!.feFinalAmount }}</p>
          <p>订单编号: {{ orderInfo!.orderId }}</p>
          <p>
            购买时间: {{ new Date(orderInfo!.createTime).toLocaleString() }}
          </p>
        </div>
        <n-button
          style="margin-top: 50px"
          type="info"
          @click="closeSuccessModal"
          >关闭</n-button
        >
      </div>
    </template>

    <template v-else>
      <div style="text-align: center">
        <n-icon size="60" color="#ed373c">
          <CloseCircleSharp />
        </n-icon>
        <h3 style="margin-bottom: 50px">订单查询失败</h3>
        <p style="color: #ccc">
          温馨提示: 如您已支付成功, 请您耐心等待几秒钟,
          点击重试以查看最新的订单信息
        </p>
        <p style="color: #ccc">
          如果刷新后订单状态仍未更新, 请及时联系我们的客服团队
        </p>
        <div style="margin-top: 50px">
          <n-button
            style="margin-right: 50px"
            type="warning"
            @click="retryCheckPaymentStatus"
            >重试</n-button
          >
          <n-button type="info" @click="closeModal">关闭</n-button>
        </div>
      </div>
    </template>

    <template v-if="paymentResult" #footer>
      <div style="width: 100%; text-align: center"></div>
    </template>
  </n-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { CheckmarkCircle, CloseCircleSharp } from "@vicons/ionicons5";
import {
  checkPaymentStatusApi,
  getNewOrderApi,
  getUserContractCountApi,
} from "../api/pay";
import { Message } from "../utils/tips";
import { useUserStore, usePayStore } from "@/store";

const props = defineProps({
  show: Boolean,
});

const emit = defineEmits(["update:show"]);

const paymentResult = ref<any>(null);
const userStore = useUserStore();
const orderId = ref("");
const orderInfo = ref<any>();
const payStore = usePayStore();

const checkPaymentStatus = () => {
  if (orderId.value) {
    checkPaymentStatusApi(orderId.value)
      .then((res: any) => {
        if (res.data.code === 200 && res.data.data) {
          paymentResult.value = "success";
        } else {
          paymentResult.value = "failed";
        }
      })
      .catch((err: any) => {
        paymentResult.value = "failed";
        console.error(err);
      });
  } else {
    Message.error("获取订单信息失败");
  }
};

const retryCheckPaymentStatus = () => {
  paymentResult.value = null;
  checkPaymentStatus();
};

const closeModal = () => {
  emit("update:show", false);
  paymentResult.value = null;
};

const closeSuccessModal = async () => {
  const userContractCount = await getUserContractCountApi(
    userStore.javaUser.userId
  );
  payStore.setContractCount(userContractCount.data.data.totalCount);
  emit("update:show", false);
  paymentResult.value = null;
  window.location.reload();
};

const handlePaymentCompleted = async () => {
  const userId = userStore.javaUser.userId;
  await getNewOrderApi(userId)
    .then((res) => {
      if (res.data.code === 200 && res.data.data) {
        orderId.value = res.data.data.orderId;
        orderInfo.value = res.data.data;
        checkPaymentStatus();
      } else {
        Message.error(res.data.message);
      }
    })
    .catch((err) => {
      console.error(err);
    });
};
</script>

<style scoped>
.customBtn {
  display: flex;
  justify-content: space-between;
  width: 200px;
}
</style>
