import type { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import axios from "axios";
import { i18n } from "@/i18n";
import router from "@/router";
import { useUserStore } from "@/store";
import { Dialog, Message } from "@/utils/tips";
import ApiUrl from "./url";

const t = i18n.global.t as any;

export interface HttpResponse<T = unknown> {
  code: number;
  message: string;
  result: T;
}

// 防止重复触发 mgLogout 请求
let isExecutingLogout = false;

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const javaToken = localStorage.getItem("javaToken");
    const freshToken = localStorage.getItem("freshToken");
    if (javaToken) {
      config.headers["Authorization"] = javaToken;
    }
    if (freshToken) {
      config.headers["X-Custom-Token"] = freshToken;
    }
    // 检查config.url是否包含"openai"，如果是，则使用VITE_API_DEV_URL
    if (config.url?.startsWith("/openai")) {
      console.log("Request Config: ", import.meta.env.VITE_API_TEST_URL);
      // config.baseURL = import.meta.env.VITE_API_TEST_URL;
    } else if (config.url?.startsWith("/api2")) {
      // config.baseURL = import.meta.env.VITE_API_DEV_URL;
    } else {
      config.baseURL = import.meta.env.VITE_API_BASE_URL;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 添加响应拦截器
 * 这里将 { code, message, result } 解构出来，response.data 替换成 result
 */
const successCode = [200, 201, 204];
axios.interceptors.response.use(
  async (response: AxiosResponse<HttpResponse>) => {
    const res = response.data;
    if (res.code === 202) {
      if (!isExecutingLogout) {
        isExecutingLogout = true;
        Message.error("账号在其他地方登录或登录时间过长, 请重新登录", {
          duration: 5 * 1000,
        });
        const userStore = useUserStore();
        localStorage.clear();
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });
        router.push({ name: "login" });
        window.location.reload();
        await userStore.logout();
      }
      return Promise.reject(
        new Error("账号在其他地方登录或登录时间过长, 请重新登录")
      );
    }
    if (res.code === 201) {
      Message.error("暂无权限使用该功能");
      return Promise.reject(new Error("暂无权限使用该功能"));
    }
    if (!successCode.includes(res.code)) {
      console.warn("Error: ", res);
      let msg = `${res.code}`;
      if (res.message) {
        msg += ` ${t(res.message)}`;
      }
      if (res.result) {
        msg += `: ${t(res.result)}`;
      }
      // Message.error(msg, { duration: 3 * 1000 });
      if (
        [10401].includes(res.code) &&
        !([ApiUrl.Login, ApiUrl.Logout] as Array<string>).includes(
          response.config.url || ""
        )
      ) {
        Dialog.error({
          title: t("errors.loginExpired") as string,
          content: t("tips.loginExpired"),
          positiveText: t("commons.confirm"),
          negativeText: t("commons.stayInCurrentPage"),
          onPositiveClick: async () => {
            if (!isExecutingLogout) {
              isExecutingLogout = true;
              const userStore = useUserStore();
              await userStore.logout();
              router.push({ name: "login" });
              window.location.reload();
            }
          },
        });
      }
      return Promise.reject(res);
    }
    (response.data as any) = res.result ? res.result : res;
    return response;
  },
  (error) => {
    console.error("Request Error", error);
    return Promise.reject(error);
  }
);
