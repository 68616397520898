<template>
  <n-modal
    v-model:show="visible"
    :style="{ width: '1000px' }"
    title="订单管理"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <n-card>
      <n-space align="center" justify="space-between">
        <h3>订单管理</h3>
        <n-button type="primary" round>申请开票</n-button>
      </n-space>
      <n-data-table :columns="columns" :data="orders" />
    </n-card>
  </n-modal>
</template>

<script setup lang="ts">
import { ref, watchEffect, h } from "vue";
import { NModal, NDataTable } from "naive-ui";

const visible = ref(false);

const orders = ref([
  {
    orderDate: "2024-06-12 15:00:00",
    orderNumber: "123123121235465465464",
    orderContent: "合同审核年卡",
    paymentAmount: "365元",
    paymentMethod: "支付宝",
    orderStatus: "已完成",
  },
]);

const columns = [
  {
    title: "订单日期",
    key: "orderDate",
  },
  {
    title: "订单编号",
    key: "orderNumber",
  },
  {
    title: "订单内容",
    key: "orderContent",
  },
  {
    title: "支付金额",
    key: "paymentAmount",
  },
  {
    title: "支付方式",
    key: "paymentMethod",
  },
  {
    title: "订单状态",
    key: "orderStatus",
    render(row: any) {
      return h(
        "span",
        { class: row.orderStatus === "失败" ? "red-text" : "" },
        row.orderStatus
      );
    },
  },
];

const handleOk = () => {
  visible.value = false;
};

const handleCancel = () => {
  visible.value = false;
};

const props = defineProps<{
  modelValue: boolean;
}>();

watchEffect(() => {
  visible.value = props.modelValue;
});

defineEmits(["update:modelValue"]);
</script>

<style scoped></style>
