import { createPinia } from "pinia";

import useAppStore from "./modules/app";
import useConversationStore from "./modules/conversation";
import useFileStore from "./modules/file";
import useUserStore from "./modules/user";
import useDocumentStore from "./modules/document";
import useCloudStore from "./modules/cloud";
import useFunctionalStore from "./modules/functional";
import useContractStore from "./modules/contract";
import usePayStore from "./modules/pay";

const pinia = createPinia();

export {
  useAppStore,
  useConversationStore,
  useFileStore,
  useUserStore,
  useDocumentStore,
  useCloudStore,
  useFunctionalStore,
  useContractStore,
  usePayStore,
};

export default pinia;
