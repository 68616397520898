import axios from "axios";

import ApiUrl from "./url";

type recordDataType = {
  type: string;
  phoneOrUser: string;
  startTime?: Date;
  cutTime?: Date;
};
export function getUserList() {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.UserGetList,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.request(config);
}

export function deleteUserById(id: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.UserDeleteApi,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId: id,
    },
  };
  return axios.request(config);
}

export function updateUserInfo(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.UserDeleteApi,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}

export function updateRoleInfo(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.UserUpdateRoleApi,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}

export function getRoleInfo() {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.UserGetRoleApi,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.request(config);
}

export function getRoleById(roleId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.SystemGetRoleById,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      roleId,
    },
  };
  return axios.request(config);
}

export function updateFunctionCount(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.SystemUpdateFunctionCount,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}

export function getInvitationCode() {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.InvitationCode,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.request(config);
}

export function getStatisticsTableData(phoneOrUser: string) {
  let config: any = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.SystemMarginStatistics,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      phoneOrUser,
    },
  };
  return axios.request(config);
}

export function getStatisticsModalData({
  type,
  phoneOrUser,
  startTime,
  cutTime,
}: recordDataType) {
  let config: any = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.SystemGetUserRecord,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      type,
      phoneOrUser,
      startTime,
      cutTime,
    },
  };
  return axios.request(config);
}
export function getStatisticsContractModalData(userId: string) {
  let config: any = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.SystemStatisticsContractDetail,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
    },
  };
  return axios.request(config);
}
export function getStatisticsFilesModalData(userId: string) {
  let config: any = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.SystemStatisticsFileDetail,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
    },
  };
  return axios.request(config);
}
export function useActivationCode(userId: string, code: string) {
  let config: any = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.SystemUseActivationCode,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
      code,
    },
  };
  return axios.request(config);
}
export function getRecordById(id: string) {
  let config: any = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.SystemGetRecordById,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      id,
    },
  };
  return axios.request(config);
}
