import axios from "axios";

import ApiUrl from "./url";

export function uploadUrlApi(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.ProUploadFiles,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return axios.request(config);
}

export function getAllCloudHistoryList(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.ProAllHistoryList,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}

export function getCloudConversationFilesApi(id: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.ProGetCloudConversationFilesById,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      conversationId: id,
    },
  };
  return axios.request(config);
}

export function deleteCloudConversationFilesApi(
  conversationId: string,
  fileId: string
) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.ProDelCloudConversationFilesById,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      conversationId,
      fileId,
    },
  };
  return axios.request(config);
}

export function getCloudConversationHistoryApi(conversationId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.ProGetHistoryById,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      conversationId,
    },
  };
  return axios.request(config);
}

export function deleteCloudConversationApi(conversationId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.ProDeleteConversation,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      conversationId,
    },
  };
  return axios.request(config);
}

export function addNewConversationApi(
  userId: string,
  conversationTitle: string
) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.ProAddNewConversation,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
      conversationTitle,
    },
  };
  return axios.request(config);
}

export function renameConversationApi(
  conversationId: string,
  conversationTitle: string
) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.ProReNameConversation,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      conversationId,
      conversationTitle,
    },
  };
  return axios.request(config);
}

export function websoketChat() {
  const protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const url = `${protocol}://${window.location.host}${ApiUrl.ProWebsoketChat}`;
  return url;
}

export function testToModel(data: any) {
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(ApiUrl.ProTextModel, config)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text(); // 获取响应文本
    })
    .then((text) => {
      // 假设 text 是你的 JSON 字符串，需要解析成 JSON 对象
      try {
        const json = JSON.parse(text);
        if (json.data) {
          // 如果数据中包含 data 字符串，再次解析该字符串
          return JSON.parse(json.data);
        } else {
          // 如果数据中不包含 data 字符串，直接返回 json 对象
          return json;
        }
      } catch (e: any) {
        throw new Error("Error parsing JSON data: " + e.message);
      }
    });
}
