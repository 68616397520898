<template>
  <n-modal
    :show="isVisible"
    title="支付"
    preset="card"
    :style="{ width: '800px' }"
    :mask-closable="false"
    @close="closeModal"
    :loading="true"
  >
    <n-spin :show="spinLoading">
      <div>
        <n-divider>选择优惠券</n-divider>
        <n-select
          v-model:value="selectedCoupon"
          :options="couponOptions"
          label-field="label"
        />
        <n-divider />
        <div>
          <h2>支付宝支付</h2>
          <n-space align="center" justify="space-between">
            <div>
              <div
                style="display: flex; align-items: center; margin-bottom: 15px"
              >
                <div style="font-weight: 700">实付：</div>
                <div>
                  <span style="color: red; font-size: 24px"
                    >￥{{
                      price && price.finalAmount ? price.finalAmount : ""
                    }}</span
                  >
                  <span style="color: grey; margin-left: 10px"
                    >(优惠已减免
                    {{
                      price && price.discountAmount ? price.discountAmount : ""
                    }})</span
                  >
                </div>
              </div>
              <div>
                开通后有效期至：<span style="color: #238cff">{{
                  calculateExpireTime
                    ? new Date(calculateExpireTime).toLocaleDateString()
                    : ""
                }}</span>
              </div>
            </div>
            <n-button type="primary" @click="handlePayment">去支付</n-button>
          </n-space>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-top: 80px;
          "
        >
          <div>
            支付即视为同意
            <span style="color: #238cff; cursor: pointer"
              >《会员服务协议》</span
            >
          </div>
          <div>
            支付遇到问题?
            <!-- <span style="color: #238cff; cursor: pointer">联系客服</span> -->
            <n-popover trigger="hover">
              <template #trigger>
                <n-button color="#238cff" text> 联系客服 </n-button>
              </template>
              <img
                width="180px"
                src="/service-qrcode.png"
                alt="专属客服二维码"
              />
            </n-popover>
          </div>
        </div>
      </div>
    </n-spin>
  </n-modal>
  <PaymentConfirmModal
    :show="paymentConfirmModalVisible"
    @update:show="paymentConfirmModalVisible = $event"
  />
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from "vue";
import {
  getAvailableCouponListApi,
  getCalculateFinalPriceApi,
  getExpireTimeApi,
} from "@/api/pay";
import { useUserStore } from "@/store";
import { Message } from "@/utils/tips";
import PaymentConfirmModal from "./PaymentConfirmModal.vue";

interface Coupon {
  id: string;
  coupon: {
    couponName: string;
    endTime: string;
    discountValue: number;
  };
}

const props = defineProps<{
  show: boolean;
  rightsId: string;
  rightsDiscountPrice: number;
  orderType: number;
}>();

const emit = defineEmits<{
  (e: "confirmPayment", couponId: string | null): void;
  (e: "update:show", flag: boolean): void;
}>();

const isVisible = ref(props.show);
const spinLoading = ref(false);
const rightsId = ref(props.rightsId);
const orderType = ref(props.orderType);
const calculateExpireTime = ref();
const userStore = useUserStore();
const paymentConfirmModalVisible = ref(false);
const price = ref<any>({
  discountAmount: 0,
  finalAmount: props.rightsDiscountPrice,
  totalAmount: props.rightsDiscountPrice,
});
const coupons = ref<Coupon[]>([
  {
    id: "no-coupon",
    coupon: {
      couponName: "不使用任何优惠券",
      endTime: "",
      discountValue: 0,
    },
  },
]);
const selectedCoupon = ref<string | null>(null);

watch(
  () => props.show,
  (newValue) => {
    isVisible.value = newValue;
  }
);

watch(isVisible, (newVal) => {
  if (newVal) {
    getAvailableCouponList();
    getExpireTimeApi({
      userId: userStore.javaUser.userId,
      rightsId: props.rightsId,
    })
      .then((res) => {
        calculateExpireTime.value = res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
});

watch(
  () => props.rightsId,
  (newValue) => {
    rightsId.value = newValue;
    // getAvailableCouponList();
  }
);
watch(
  () => props.orderType,
  (newValue) => {
    orderType.value = newValue;
  }
);

watch([isVisible, selectedCoupon], ([isVisibleValue, selectedCouponValue]) => {
  if (isVisibleValue) {
    if (selectedCouponValue !== null) {
      const selected = coupons.value.find(
        (option) => option.id === selectedCouponValue
      );
      if (selected && selected.id) {
        if (selected.id !== "no-coupon") {
          const selectRight = coupons.value.find(
            (item: any) => item.id === selected.id
          );
          const data = {
            userId: userStore.javaUser.userId,
            userCouponId: selectRight?.id,
            rightsId: rightsId.value,
            feTotalAmount: props.rightsDiscountPrice,
          };
          getCalculateFinalPriceApi(data)
            .then((res) => {
              price.value = {
                discountAmount: res.data.data.discountAmount,
                finalAmount: res.data.data.finalAmount,
                totalAmount: res.data.data.totalAmount,
              };
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          price.value = {
            discountAmount: 0,
            finalAmount: props.rightsDiscountPrice,
            totalAmount: props.rightsDiscountPrice,
          };
        }
      }
    }
  }
});

const openPaymentConfirmModal = () => {
  paymentConfirmModalVisible.value = true;
};

const getAvailableCouponList = async () => {
  if (rightsId.value) {
    spinLoading.value = true;
    await getAvailableCouponListApi(userStore.javaUser.userId, rightsId.value)
      .then((res) => {
        if (res.data && res.data.data && res.data.data.length > 0) {
          coupons.value = [
            ...res.data.data,
            {
              id: "no-coupon",
              coupon: {
                couponName: "不使用任何优惠券",
                endTime: "",
                discountValue: 0,
              },
            },
          ];
        } else {
          coupons.value = [
            {
              id: "no-coupon",
              coupon: {
                couponName: "不使用任何优惠券",
                endTime: "",
                discountValue: 0,
              },
            },
          ];
        }
      })
      .catch((err) => {
        console.log(err);
        coupons.value = [
          {
            id: "no-coupon",
            coupon: {
              couponName: "不使用任何优惠券",
              endTime: "",
              discountValue: 0,
            },
          },
        ];
      })
      .finally(() => {
        spinLoading.value = false;
        // 默认选中第一项
        selectedCoupon.value = coupons.value[0].id;
      });
  }
};

// const calculateFinalPrice = () => {
//   getCalculateFinalPriceApi();
// };

const couponOptions = computed(() =>
  coupons.value.map((coupon) => ({
    label: coupon.coupon.endTime
      ? `${coupon.coupon.couponName} (有效期至${coupon.coupon.endTime.slice(
          0,
          10
        )})`
      : coupon.coupon.couponName,
    value: coupon.id,
    discountValue: coupon.coupon.discountValue,
  }))
);

const handlePayment = () => {
  const userId = userStore.javaUser?.userId;
  if (rightsId.value) {
    const viewerUrl = `${window.location.origin}/api2/alipay/alipay?userId=${userId}&rightsId=${rightsId.value}&interfaceInfoId=${props.orderType}&feTotalAmount=${price.value.totalAmount}&feDiscountAmount=${price.value.discountAmount}&feFinalAmount=${price.value.finalAmount}&userCouponId=${selectedCoupon.value !== "no-coupon" ? selectedCoupon.value : ""}&paymentMethod=支付宝`;
    const link = document.createElement("a");
    link.href = viewerUrl;
    console.log(viewerUrl, "viewerUrl");
    link.target = "_blank"; // Ensure it opens in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // 关闭当前购买弹窗
    closeModal();

    // 打开支付确认弹窗
    openPaymentConfirmModal();
  } else {
    Message.info("网络异常, 商品信息查询失败, 请稍后重试");
  }
};

const closeModal = () => {
  emit("update:show", false);
  isVisible.value = false;
};
</script>
