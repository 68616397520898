<template>
  <n-config-provider :locale="locale" :theme-overrides="theme">
    <n-global-style />
    <div class="w-full box-border flex flex-col">
      <n-layout>
        <PageHeader v-if="!route.meta.hideHeaderbars && userStore.javaUser" />
        <div :style="contentStyle">
          <router-view />
        </div>
      </n-layout>
    </div>
  </n-config-provider>
</template>

<script setup lang="ts">
import { useEventListener } from "@vueuse/core";
import { darkTheme, enUS, zhCN } from "naive-ui";
import { computed } from "vue";
import { useRoute } from "vue-router";

import PageHeader from "./components/PageHeader.vue";
import { useAppStore, useUserStore } from "./store";

const appStore = useAppStore();
const userStore = useUserStore();
const route = useRoute();

// 计算content部分的样式
const contentStyle = computed(() => {
  if (route.meta.hideHeaderbars) {
    return {
      height: "100vh",
    }; // 如果需要隐藏Header，返回一个空对象（没有样式）
  } else {
    // 否则，返回计算后的样式对象
    return {
      // height: `100vh`,
      height: `calc(100dvh - var(--header-height))`,
    };
  }
});

const setFullHeight = () => {
  const headerHeight = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue(
      "--header-height"
    ),
    10
  );
  const windowHeight = window.innerHeight;
  // 动态计算并设置高度
  document.documentElement.style.setProperty(
    "--full-height",
    `${windowHeight - headerHeight}px`
  );
};

useEventListener("resize", setFullHeight);

const lightThemeOverrides = {
  common: {
    primaryColor: "#1272ea",
    primaryColorHover: "#00b6f6",
    primaryColorPressed: "#d3e5fb",
    textColorBase: "#1272ea",
  },
  Menu: {
    itemColorActive: "white", // 已激活菜单项的颜色
    itemColorHover: "#d3e5fb", // 鼠标悬停菜单项的颜色
    itemTextColor: "#000", // 菜单项文本颜色
    itemTextColorActive: "#000", // 已激活菜单项的文本颜色
    itemTextColorHover: "#000", // 鼠标悬停菜单项的文本颜色
    // 其它你可能需要覆盖的菜单样式变量...
  },
};

const theme = computed(() => {
  if (appStore.theme == "dark") {
    return lightThemeOverrides;
  } else {
    return lightThemeOverrides;
  }
});

const locale = computed(() => {
  if (appStore.language == "zh-CN") return zhCN;
  else return enUS;
});
</script>
