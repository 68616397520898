import { defineStore } from "pinia";

// 假设这些是新定义的API请求方法，需要您根据实际后端接口进行实现
import {
  deleteCloudConversationApi,
  getAllCloudHistoryList,
  getCloudConversationHistoryApi,
} from "@/api/cloud";

import { CloudState } from "../types";

export const newCloudConversationId = "new_cloud_conversation";

// 定义新仓库
const useCloudStore = defineStore("cloud", {
  state: (): CloudState => ({
    conversations: [],
    conversationHistoryMap: {},
    newConversation: null,
    conversationId: null,
    cloudMaterial: [],
    homePageData: "",
    homePageEnter: false,
  }),
  actions: {
    async fetchAllCloudConversations(data: any) {
      const result = (await getAllCloudHistoryList(data)).data;
      this.$patch({ conversations: result });
    },
    // 这里省略其他方法的具体实现，只做了名称更改和简化
    // 根据实际的API逻辑，您需要补充完整每个方法的实现细节
    async fetchCloudConversationHistory(
      conversation_id: string,
      userName: string
    ) {
      if (this.conversationHistoryMap[conversation_id]) {
        return this.conversationHistoryMap[conversation_id];
      }
      // 简化的示例逻辑
      const result = (await getCloudConversationHistoryApi(conversation_id))
        .data;
      this.conversationHistoryMap[conversation_id] = result;
      return result;
    },

    setConversationId(id: string | null) {
      this.conversationId = id;
    },

    // 其他方法的实现...

    async deleteCloudConversation(conversation_id: string) {
      // 简化的示例逻辑
      await deleteCloudConversationApi(conversation_id);
      delete this.conversationHistoryMap[conversation_id];
      this.conversations = this.conversations.filter(
        (conv) => conv.conversation_id !== conversation_id
      );
    },
    setCloudMaterial(data: any) {
      this.cloudMaterial = data;
    },
    setHomePageData(data: string) {
      this.homePageData = data;
    },

    setHomePageEnter(data: boolean) {
      this.homePageEnter = data;
    },
    resetCloud() {
      this.$reset();
    },
  },
});

export default useCloudStore;
