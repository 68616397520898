import axios from "axios";

import {
  UserCreate,
  UserRead,
  UserReadAdmin,
  UserSettingSchema,
  UserUpdate,
  UserUpdateAdmin,
} from "@/types/schema";

import ApiUrl from "./url";

export type LoginData = {
  username?: string;
  userPhoneNumber?: string;
  password?: string;
  verificationCode?: string;
};
export type RegisterData = {
  userPhoneNumber: string;
  userPassword: string;
  verificationCode: string;
  email?: string;
  userRealName?: string;
};

// export function loginApi(data: LoginData) {
//   const formData = new FormData();
//   formData.set("username", data.username);
//   formData.set("password", data.password);
//   return axios.post<any>(ApiUrl.Login, formData, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   });
// }
export function getVerificationCode(userPhoneNumber: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.RegisterGetVerificationCode,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userPhoneNumber,
    },
  };
  return axios.request(config);
}

export function loginMuguaApi(data: LoginData) {
  const formData = new FormData();
  formData.set("userName", data.username!);
  formData.set("userPassword", data.password!);
  formData.set("rememberMe", "false");
  return axios.post<any>(ApiUrl.LoginUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function loginSmsMuguaApi(data: any) {
  const formData = new FormData();
  formData.set("userPhoneNumber", data.userPhoneNumber);
  formData.set("verificationCode", data.verificationCode);
  formData.set("rememberMe", "false");
  if (data.invitationCode !== "") {
    formData.set("invitationCode", data.invitationCode);
  }
  return axios.post<any>(ApiUrl.LoginSmsUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function registerMuGua(userInfo: any) {
  return axios.post<UserRead>(ApiUrl.LoginRegister, userInfo);
}

export function registerApi(userInfo: UserCreate) {
  return axios.post<UserRead>(ApiUrl.Register, userInfo);
}

export function logoutApi() {
  return axios.post<any>(ApiUrl.Logout);
}

export function getAllUserApi() {
  return axios.get<UserRead[]>(ApiUrl.UserList);
}

// export function getUserMeApi() {
//   return axios.get<UserRead>(ApiUrl.UserMe);
// }

export function getMuguaUserMeApi() {
  return axios.get<any>(ApiUrl.LoginUserInfo);
}

// export function updateUserMeApi(userUpdate: Partial<UserUpdate>) {
//   return axios.patch<UserRead>(ApiUrl.UserMe, userUpdate);
// }

export function getUserByIdApi(userId: number) {
  return axios.get<UserReadAdmin>(ApiUrl.UserList + `/${userId}`);
}

export function updateUserByIdApi(
  userId: number,
  userUpdateAdmin: Partial<UserUpdateAdmin>
) {
  return axios.patch<UserReadAdmin>(
    ApiUrl.UserList + `/${userId}`,
    userUpdateAdmin
  );
}

export function deleteUserApi(user_id: number) {
  return axios.delete(ApiUrl.UserList + `/${user_id}`);
}

export function updateUserSettingApi(
  userId: number,
  userSetting: Partial<UserSettingSchema>
) {
  return axios.patch<UserReadAdmin>(
    ApiUrl.UserList + `/${userId}/setting`,
    userSetting
  );
}

export function checkToken(token: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.CheckToken,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      permissionName: token,
    },
  };
  return axios.request(config);
}

export function getUserModalInfo(userId: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.UserInfo,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      userId,
    },
  };
  return axios.request(config);
}

export function bindUserPhone(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.UserBindPhone,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}

export function updateUserPassword(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.UserUpdatePassword,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}

export function updateUserInfo(data: any) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: ApiUrl.UserUpdateInfo,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios.request(config);
}
