import { createRouter, createWebHistory } from "vue-router";

import createRouteGuard from "./guard";

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_ROUTER_BASE),
  routes: [
    {
      path: "/",
      redirect: "homepage",
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home.vue"),
      meta: {
        requiresAuth: false,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/homepage",
      name: "homePage",
      component: () => import("@/views/homePage/index.vue"),
      meta: {
        hideHeaderbars: true,
        requiresAuth: true,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/login/index.vue"),
      meta: {
        requiresAuth: false,
        hideHeaderbars: true,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/conversation",
      name: "conversation",
      component: () => import("@/views/conversation/index.vue"),
      meta: {
        requiresAuth: true,
        roles: ["superuser", "user"],
      },
      children: [
        {
          path: "longdocument",
          name: "longdocumentManagement",
          component: () =>
            import("@/views/conversation/pages/longdocument.vue"),
          meta: {
            hideSidebars: true,
            requiresAuth: true,
            roles: ["superuser", "user"],
          }, // 隐藏侧边栏
        },
        {
          path: "longoutline",
          name: "longoutlineManagement",
          component: () => import("@/views/conversation/pages/longoutline.vue"),
          meta: {
            hideSidebars: true,
            requiresAuth: true,
            roles: ["superuser", "user"],
          }, // 隐藏侧边栏
        },
        {
          path: "longshow",
          name: "longshowManagement",
          component: () => import("@/views/conversation/pages/longshow.vue"),
          meta: {
            hideSidebars: true,
            requiresAuth: true,
            roles: ["superuser", "user"],
          }, // 隐藏侧边栏
        },
        {
          path: "longedit",
          name: "longEditManagement",
          component: () => import("@/views/conversation/pages/longedit.vue"),
          meta: {
            hideSidebars: true,
            requiresAuth: true,
            roles: ["superuser", "user"],
          }, // 隐藏侧边栏
          children: [
            {
              path: "longhistory",
              name: "longHistoryManagement",
              component: () =>
                import("@/views/conversation/pages/longhistory.vue"),
              meta: {
                hideSidebars: true,
                hideContent: true,
                requiresAuth: true,
                roles: ["superuser", "user"],
              }, // 隐藏侧边栏
            },
            {
              path: "analysis",
              name: "analysisManagement",
              component: () =>
                import("@/views/conversation/pages/analysis.vue"),
              meta: {
                hideSidebars: true,
                hideContent: true,
                requiresAuth: true,
                roles: ["superuser", "user"],
              }, // 隐藏侧边栏
            },
          ],
        },
      ],
    },
    {
      path: "/cloud",
      name: "cloudManagement",
      component: () => import("@/views/cloud/index.vue"),
      meta: {
        requiresAuth: true,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/conversationpro",
      name: "conversationpro",
      component: () => import("@/views/conversationPro/index.vue"),
      meta: {
        requiresAuth: true,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/contract",
      name: "contractReview",
      component: () => import("@/views/contract/index.vue"),
      meta: {
        requiresAuth: true,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/dialogue",
      name: "dialogue",
      component: () => import("@/views/dialogue/index.vue"),
      meta: {
        requiresAuth: true,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/analysis",
      name: "analysis",
      component: () => import("@/views/analysis/index.vue"),
      meta: {
        requiresAuth: true,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/drafting",
      name: "drafting",
      component: () => import("@/views/drafting/index.vue"),
      meta: {
        requiresAuth: true,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/functional",
      name: "functional",
      redirect: "/functional/inputinfo",
      component: () => import("@/views/functionalPage/index.vue"),
      meta: {
        requiresAuth: true,
        hideHeaderbars: true,
        roles: ["superuser", "user"],
      },
      children: [
        {
          path: "inputinfo",
          name: "inputinfo",
          component: () => import("@/views/functionalPage/pages/Inputinfo.vue"),
          meta: {
            hideSidebars: true,
            hideContent: true,
            requiresAuth: true,
            roles: ["superuser", "user"],
          }, // 隐藏侧边栏
        },
        {
          path: "cases",
          name: "cases",
          component: () => import("@/views/functionalPage/pages/Cases.vue"),
          meta: {
            hideSidebars: true,
            hideContent: true,
            requiresAuth: true,
            roles: ["superuser", "user"],
          }, // 隐藏侧边栏
        },
        {
          name: "extraction",
          path: "extraction",
          component: () =>
            import("@/views/functionalPage/pages/Extraction.vue"),
          meta: {
            hideSidebars: true,
            hideContent: true,
            requiresAuth: true,
            roles: ["superuser", "user"],
          }, // 隐藏侧边栏
        },
      ],
    },
    {
      path: "/conv/:conversation_id",
      name: "conversationHistory",
      component: () => import("@/views/conversation/history-viewer.vue"),
      meta: {
        requiresAuth: true,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/admin",
      name: "admin",
      redirect: "/admin/system",
      component: () => import("@/views/admin/index.vue"),
      meta: {
        requiresAuth: true,
        roles: ["superuser"],
      },
      children: [
        {
          path: "system",
          name: "systemManagement",
          component: () => import("@/views/admin/pages/system_manager.vue"),
        },
        {
          path: "user",
          name: "userManagement",
          component: () => import("@/views/admin/pages/user_manager.vue"),
        },
        {
          path: "newuser",
          name: "newUserManagement",
          component: () => import("@/views/admin/pages/new_user_manager.vue"),
        },
        {
          path: "conversation",
          name: "conversationManagement",
          component: () =>
            import("@/views/admin/pages/conversation_manager.vue"),
        },
        {
          path: "log",
          name: "logViewer",
          component: () => import("@/views/admin/pages/log_viewer.vue"),
        },
        {
          path: "openai_settings",
          name: "openaiSettings",
          component: () => import("@/views/admin/pages/openai_settings.vue"),
        },
        {
          path: "config",
          name: "configManagement",
          component: () => import("@/views/admin/pages/config_manager.vue"),
        },
        {
          path: "statistics",
          name: "statistics",
          component: () => import("@/views/admin/pages/statistics.vue"),
        },
        {
          path: "userRecord",
          name: "userRecord",
          component: () => import("@/views/admin/pages/userRecord.vue"),
        },
      ],
    },
    {
      path: "/redirect",
      name: "redirectWrapper",
      children: [
        {
          path: "/redirect/:path",
          name: "Redirect",
          component: () => import("@/views/redirect/index.vue"),
          meta: {
            requiresAuth: false,
            roles: ["superuser", "user"],
          },
        },
      ],
    },
    {
      path: "/error",
      name: "errorPageWrapper",
      children: [
        {
          path: "/error/403",
          name: "403",
          component: () => import("@/views/error/403.vue"),
          meta: {
            requiresAuth: false,
            roles: ["superuser", "user"],
          },
        },
        {
          path: "/error/404",
          name: "404",
          component: () => import("@/views/error/404.vue"),
          meta: {
            requiresAuth: false,
            roles: ["superuser", "user"],
          },
        },
      ],
    },
    { path: "/:pathMatch(.*)*", name: "NotFound", redirect: "/error/404" },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

createRouteGuard(router);

export default router;
