import { defineStore } from "pinia";

const useFunctionalStore = defineStore("functional", {
  state: () => ({
    menuSelected: "cases",
    keyWords: "",
  }),
  actions: {
    setMenuSelected(value: string) {
      this.menuSelected = value;
    },
    setKeyWords(value: string) {
      this.keyWords = value;
    },
    resetFunctional() {
      this.$reset();
    },
  },
});

export default useFunctionalStore;
