<template>
  <n-modal
    preset="card"
    :style="{ width: '1000px' }"
    :mask-closable="false"
    :show="showModal"
    title="商品"
    @close="closeModal"
  >
    <n-space vertical size="large">
      <div v-for="item in packages" :key="item.rightsId" class="package-item">
        <n-card>
          <div class="package-details">
            <div>
              <h3>{{ item.rightsName }}vip</h3>
              <p>
                {{ item.validDate }}天内可使用{{
                  item.rightsAllCount && item.rightsAllCount.contractReviewCount
                    ? item.rightsAllCount.contractReviewCount
                    : 0
                }}次
              </p>
            </div>
            <div class="package-actions">
              <n-button type="primary" @click="buyPackage(item)">购买</n-button>
              <div style="margin-top: 20px">
                <span class="price">¥{{ item.rightsDiscountPrice }}</span>
                <span
                  v-if="item.rightsOriginalPrice > item.rightsDiscountPrice"
                  class="original-price"
                >
                  ¥{{ item.rightsOriginalPrice }}
                </span>
              </div>
            </div>
          </div>
        </n-card>
      </div>
    </n-space>
    <PaymentModal
      v-model:show="showPaymentModal"
      :rightsId="rightsId"
      :rightsDiscountPrice="rightsDiscountPrice"
      :orderType="orderType"
      @update:show="closePaymentModal"
    />
  </n-modal>
</template>

<script setup>
import { ref, watch } from "vue";
import { NModal, NCard, NButton, NSpace } from "naive-ui";
import PaymentModal from "./PaymentModal.vue";
import { usePayStore } from "@/store";

const showModal = ref(false);
const showPaymentModal = ref(false);
const payStore = usePayStore();
const rightsId = ref("");
const rightsDiscountPrice = ref(365);
const orderType = ref(0);

// 这里替换为您从后端接收到的数据
const packages = ref(payStore.rightsList);

watch(
  () => payStore.rightsList,
  (newVal, oldVal) => {
    if (newVal) {
      packages.value = newVal;
    }
  }
);
const buyPackage = (item) => {
  console.log(`购买套餐: ${item.rightsName}`);
  // 在这里处理购买逻辑
  console.log(item.rightsId);
  rightsId.value = item.rightsId;
  rightsDiscountPrice.value = item.rightsDiscountPrice;
  orderType.value = item.orderType;
  showPaymentModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};

const closePaymentModal = (flag) => {
  showPaymentModal.value = flag;
};
</script>

<style scoped>
.package-item {
  margin-bottom: 16px;
}

.package-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.package-actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 120px;
}

.price {
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
}

.original-price {
  text-decoration: line-through;
  color: gray;
  margin-left: 8px;
}
</style>
