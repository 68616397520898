<template>
  <n-card class="user-options-card" style="width: 30dvb">
    <n-space vertical>
      <!-- 中部部分：消息提示 -->
      <n-card content-style="padding: 15px;" bordered class="info-card">
        <div class="info-box">
          <div>
            <div>
              <div v-if="!expirationTime">暂无商品权益</div>
              <div v-else>会员权益</div>
            </div>
            <div v-if="expirationTime" style="color: gray">
              VIP到期时间: {{ expirationTime }}
            </div>
          </div>
          <div>
            <n-button
              v-if="!expirationTime"
              round
              size="medium"
              class="buy-button"
              @click="openCommodityModal"
            >
              领券购买
            </n-button>
            <n-button
              v-else
              round
              size="medium"
              class="buy-button"
              @click="openCommodityModal"
            >
              续费
            </n-button>
          </div>
        </div>
        <div class="count-box">
          <div>
            <div>无限制</div>
            <p>智能对话</p>
          </div>
          <div>
            <div>{{ totalCount ? totalCount : 0 }}次</div>
            <p>合同审查</p>
          </div>
        </div>
      </n-card>

      <!-- 顶部部分：商品和优惠券统计信息 -->
      <div style="display: flex; justify-content: space-between">
        <n-card
          style="cursor: pointer"
          content-style="padding: 20px 20px;"
          bordered
          class="stat-card"
          @click="handleCoupons"
        >
          <n-space justify="space-around" align="center">
            <div>优惠券</div>
            <div>{{ userCouponsList.length }}</div>
          </n-space>
        </n-card>
      </div>

      <!-- 底部部分：用户操作选项 -->
      <n-space vertical style="margin-top: 20px">
        <n-button text @click="openUserInfoModal">
          <n-icon size="18"><InformationCircleOutline /></n-icon> &nbsp;
          用户信息
        </n-button>
        <!-- <n-button text>
          <n-icon size="18"><ShareSocial /></n-icon> &nbsp; 我的邀请
        </n-button> -->
        <n-button text @click="openOrderInfoModal">
          <n-icon size="18"><CartOutline /></n-icon> &nbsp; 订单管理
        </n-button>
        <!-- <n-button text>
          <n-icon size="18"><ReaderOutline /></n-icon> &nbsp; 发票管理
        </n-button> -->
        <n-button text @click="handleLogout">
          <n-icon size="18"><LogOutOutline /></n-icon> &nbsp; 退出登录
        </n-button>
      </n-space>
    </n-space>
    <!-- 引入并显示 UserInfoModal 组件 -->
    <UserInfoModal
      :show="userInfoModal"
      @save="handleUserSave"
      @update:show="handleCancel"
    />
    <CommodityModal v-model:show="commodityModalVisible"></CommodityModal>
    <OrderModal
      v-model:show="orderShowModal"
      @update:show="handleOrderCancel"
    ></OrderModal>
    <UserCouponsModal
      v-model:show="showCouponModal"
      @update:show="handleCouponsCancel"
      @toUse:value="handleToUse"
    ></UserCouponsModal>
  </n-card>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import {
  InformationCircleOutline,
  LogOutOutline,
  CartOutline,
} from "@vicons/ionicons5";
import { NButton, NIcon, NCard, NSpace } from "naive-ui";
import { useUserStore, useAppStore, usePayStore } from "@/store";
import router from "@/router";
import { Dialog, Message } from "@/utils/tips";
import UserInfoModal from "./UserInfoModal.vue";
import { updateUserInfo } from "@/api/user";
import {
  getUserCommodityApi,
  getUserContractCountApi,
  getUserCouponsApi,
  getRightsListApi,
} from "@/api/pay";
import CommodityModal from "@/components/CommodityModal.vue";
import OrderModal from "@/components/OrderInfoModal.vue";
import UserCouponsModal from "@/components/UserCouponsModal.vue";

const userStore = useUserStore();
const appStore = useAppStore();
const payStore = usePayStore();
const userInfoModal = ref(false);
const expirationTime = ref<null | string>(null);
const totalCount = ref<null | number>(payStore.contractCount);
const userCouponsList = ref([]);
const commodityModalVisible = ref(false);
const orderShowModal = ref(false);
const showCouponModal = ref(false);

watch(
  () => payStore.contractCount,
  (newVal) => {
    totalCount.value = newVal;
  }
);

onMounted(async () => {
  try {
    const userCommodit = await getUserCommodityApi(
      userStore.javaUser.userId,
      true
    );
    const userContractCount = await getUserContractCountApi(
      userStore.javaUser.userId
    );
    const userCoupons = await getUserCouponsApi(
      userStore.javaUser.userId,
      false
    );
    const rights = await getRightsListApi(false);
    if (
      userCommodit &&
      userCommodit.data &&
      userCommodit.data.data &&
      userCommodit.data.data.expirationTime
    ) {
      expirationTime.value = new Date(
        userCommodit.data.data.expirationTime
      ).toLocaleDateString();
    }
    // totalCount.value = userContractCount.data.data.totalCount;
    payStore.setContractCount(userContractCount.data.data.totalCount);
    if (userCoupons && userCoupons.data && userCoupons.data.data) {
      userCouponsList.value = userCoupons.data.data;
    }
    if (rights.data && rights.data.data) {
      payStore.setRightsList(rights.data.data);
    }
  } catch (err) {
    console.log(err);
  }
});

// 打开 CommodityModal 弹窗的方法
const openCommodityModal = () => {
  commodityModalVisible.value = true;
};

const openUserInfoModal = () => {
  userInfoModal.value = true; // 调用 UserInfoModal 组件的 openModal 方法
};
const openOrderInfoModal = () => {
  orderShowModal.value = true;
};

const handleCoupons = () => {
  showCouponModal.value = true;
};

function handleUserSave(info: any) {
  console.log("父组件接收到的用户信息:", info);
  // 执行保存逻辑
  const data = {
    userId: userStore.javaUser.userId,
    userName: info.userName,
    nickName: info.nickName,
    gender: info.gender,
    company: info.company,
    position: info.position,
    industry: info.industry,
    location: info.location,
  };
  updateUserInfo(data)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  userInfoModal.value = false;
}

function handleCancel() {
  userInfoModal.value = false;
}

function handleOrderCancel() {
  orderShowModal.value = false;
}

function handleCouponsCancel() {
  showCouponModal.value = false;
}

function handleToUse() {
  commodityModalVisible.value = true;
}

const handleLogout = () => {
  Dialog.info({
    title: "退出登录",
    content: "确定要退出吗？",
    positiveText: "确定",
    negativeText: "取消",
    onPositiveClick: async () => {
      await userStore.logout();
      // Clear stores and redirect to login
      Message.success("退出成功");
      await router.push({ name: "login" });
    },
  });
};
</script>

<style scoped>
.user-options-card {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  /* display: none; */
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.firstStatCard {
  background-color: rgba(113, 186, 225, 0.1);
  border-radius: 8px;
  padding: 10px 0;
  margin-right: 5px;
}

.stat-card {
  background-color: rgba(113, 186, 225, 0.1);
  border-radius: 8px;
}

.info-card {
  background-color: rgba(113, 186, 225, 0.1);
  border-radius: 8px;
  padding: 10px;
}

.info-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.count-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  p {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0;
  }
}
</style>
