import { defineStore } from "pinia";

const usePayStore = defineStore("pay", {
  state: () => ({
    rightsList: [],
    contractCount: 0,
  }),
  actions: {
    setRightsList(list: any) {
      this.rightsList = list;
    },
    setContractCount(count: number) {
      this.contractCount = count;
    },
  },
});

export default usePayStore;
