<template>
  <div>
    <n-modal
      v-model:show="showModal"
      preset="card"
      title="用户订单"
      :style="{ width: '1000px' }"
      content-style="padding: 15px;"
      @close="emit('update:show', false)"
    >
      <n-data-table
        :columns="columns"
        :data="paginatedData"
        :row-key="(row) => row.orderId"
      />
      <n-pagination
        v-model:page="currentPage"
        :page-count="totalPages"
        :page-size="pageSize"
        @update:page="handlePageChange"
        style="float: right; margin-top: 16px"
      />
    </n-modal>

    <!-- 新增的弹窗组件 -->
    <n-modal
      v-model:show="showDetailModal"
      preset="dialog"
      :title="modalTitle"
      :style="{ width: '600px' }"
      content-style="padding: 20px;"
    >
      <div style="display: flex; flex-direction: column">
        <div
          v-for="(value, key) in modalContent"
          :key="key"
          style="display: flex; margin-bottom: 8px"
        >
          <div style="flex: 1; font-weight: bold">{{ key }}</div>
          <div style="flex: 2">{{ value }}</div>
        </div>
        <n-button
          v-if="modalType === 'refund'"
          type="error"
          style="margin-top: 16px; align-self: center"
          @click="confirmRefund"
        >
          确认退款
        </n-button>
      </div>
    </n-modal>
  </div>
</template>

<script setup>
import { ref, computed, watch, h, onMounted, nextTick } from "vue";
import { NModal, NDataTable, NPagination, NEllipsis, NButton } from "naive-ui";
import { getOrderByUserIdApi } from "@/api/pay";
import { useUserStore } from "@/store";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:show"]);

const showModal = ref(props.show);
const currentPage = ref(1);
const pageSize = 10;
const userStore = useUserStore();
const orders = ref([]);
const totalLength = ref(0);
const showDetailModal = ref(false);
const modalTitle = ref("");
const modalType = ref("");
const modalContent = ref({});

onMounted(() => {
  getTableData();
});

const getTableData = async () => {
  const data = {
    userId: userStore.javaUser.userId,
    currentPage: currentPage.value - 1,
    pageSize: pageSize,
  };
  try {
    const res = await getOrderByUserIdApi(data);
    if (
      res.data.code === 200 &&
      res.data &&
      res.data.data &&
      res.data.data.orderList &&
      res.data.data.orderList.length > 0
    ) {
      orders.value = res.data.data.orderList;
      totalLength.value = res.data.data.totalItem;
      await nextTick();
    } else {
      orders.value = [];
      totalLength.value = 0;
    }
  } catch (err) {
    console.error(err);
    orders.value = [];
    totalLength.value = 0;
  }
};

const paginatedData = computed(() => {
  return orders.value || [];
});

const totalPages = computed(() => {
  return Math.ceil(totalLength.value / pageSize);
});

const handlePageChange = (page) => {
  currentPage.value = page;
  getTableData();
};

const columns = [
  {
    title: "创建日期",
    key: "createTime",
    render: (row) => new Date(row.createTime).toLocaleDateString(),
  },
  {
    title: "订单编号",
    key: "orderId",
    render: (row) =>
      h(
        NEllipsis,
        { style: "max-width: 200px" },
        { default: () => row.orderId }
      ),
  },
  { title: "订单详情", key: "rightsName" },
  { title: "实付金额", key: "feFinalAmount" },
  { title: "支付方式", key: "paymentMethod" },
  { title: "订单状态", key: "statusName" },
  {
    title: "操作",
    key: "actions",
    render: (row) =>
      h("div", [
        h(
          NButton,
          {
            style: "margin-right: 10px",
            type: "info",
            size: "small",
            onClick: () => handleViewDetails(row),
          },
          { default: () => "查看详情" }
        ),
        h(
          NButton,
          {
            size: "small",
            onClick: () => handleApplyRefund(row),
          },
          { default: () => "申请退款" }
        ),
      ]),
  },
];

const handleViewDetails = (row) => {
  modalTitle.value = "订单详情";
  modalType.value = "details";
  modalContent.value = {
    订单编号: row.orderId,
    订单状态: row.statusName,
    商品名称: row.rightsName,
    支付方式: row.paymentMethod,
    实付金额: row.feFinalAmount,
    优惠券: row.couponName || "无",
    购买时间: new Date(row.createTime).toLocaleString(),
  };
  showDetailModal.value = true;
};

const handleApplyRefund = (row) => {
  modalTitle.value = "申请退款";
  modalType.value = "refund";
  modalContent.value = {
    订单编号: row.orderId,
    商品名称: row.rightsName,
    实付金额: row.feFinalAmount,
    优惠券: row.coupon || "无",
    购买时间: new Date(row.createTime).toLocaleString(),
  };
  showDetailModal.value = true;
};

const confirmRefund = () => {
  // 这里可以添加确认退款的逻辑
  console.log("确认退款");
  showDetailModal.value = false;
};

watch(
  () => props.show,
  (newVal) => {
    showModal.value = newVal;
    if (newVal) {
      getTableData();
    }
  }
);
</script>

<style scoped>
/* 你可以在这里添加一些样式 */
</style>
